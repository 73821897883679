import {
    Box,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import ObsListLocationForm from './ObsListLocationForm';
import { addYears, format, isAfter, isValid, parse } from 'date-fns';
import SecrecySelect from '../../../components/formControls/SecrecySelect';
import { Formik, FormikHelpers, Form, FormikErrors } from 'formik';
import { schema_list_input } from '../../../schemas/schemas';
import * as z from 'zod';
import { ObservationFormStepComponent } from '../ObservationFormApp';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { EListTypes, ListObservationMode, ListRights, ListTypes, SecrecyCategory } from '../../../schemas/enums';
import FormikObserver from '../../../components/FormikObserver';
import { EditRounded, PersonRounded } from '@mui/icons-material';
import { IPlace } from '../../../schemas/interfaces';
import { distanceTwoPoints } from '../../../services/helpers';
import { useAuth } from '../../../services/authenticator';
import translateErrorMessage from '../../../services/errorMessages';
import CreateListTour from '../../../components/joyride/CreateListTour';
import NumberField from '../../../components/formControls/NumberField';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import scrollToFirstError from '../../../lib/scrollToFirstError';

const validationSchema = schema_list_input.pick({
    date: true,
    timeStart: true,
    timeEnd: true,
    observersText: true,
    observersNumber: true,
    hideObservers: true,
    municipalityPartId: true,
    distanceCovered: true,
    coordinates: true,
    track: true,
    note: true,
    siteNote: true,
    siteName: true,
    secrecyLevel: true,
    secretUntil: true,
    type: true,
    observationMode: true,
});

export type ObsListDetailsFormData = z.infer<typeof validationSchema>;

export interface ObsListDetailsFormValues extends ObsListDetailsFormData {
    _Distance: number | null;
    _DistanceUnit: 'm' | 'km';
    _Secret: boolean;
    _Municipality?: IPlace;
}

const emptyObsList: ObsListDetailsFormValues = {
    type: ListTypes.partial,
    date: null,
    timeStart: null,
    timeEnd: null,
    secrecyLevel: null,
    secretUntil: null,
    hideObservers: false,
    track: null,
    observersNumber: null,
    observersText: null,
    distanceCovered: null,
    note: null,
    siteName: null,
    siteNote: null,
    municipalityPartId: null,
    coordinates: null,
    source: 'web',
    sourceVersion: process.env.REACT_APP_VERSION,
    items: [],
    observationMode: ListObservationMode.area,

    _Distance: null,
    _DistanceUnit: 'm',
    _Secret: false,
} as unknown as ObsListDetailsFormValues;

const ObsListDetailsForm: ObservationFormStepComponent = (props) => {
    const { identity } = useAuth();

    const onSubmit = (values: ObsListDetailsFormValues, helpers: FormikHelpers<ObsListDetailsFormValues>) => {
        if (props.onNext) props.onNext(values).then(() => helpers.setSubmitting(false));
        else helpers.setSubmitting(false);
    };

    return (
        <Formik<ObsListDetailsFormValues>
            initialValues={{
                ...emptyObsList,
                ...props.initialValues,
                _Secret: !!props.initialValues?.secrecyLevel,
                _Distance: props.initialValues?.distanceCovered ?? null,
            }}
            validationSchema={toFormikValidationSchema(validationSchema)}
            onSubmit={onSubmit}
            validate={(values) => {
                const errors: FormikErrors<ObsListDetailsFormValues> = {};

                const now = new Date();
                now.setHours(0, 0, 0, 0);
                const userDate = new Date(values.date);
                userDate.setHours(0, 0, 0, 0);

                if (userDate && userDate > now) {
                    errors['date'] = 'errors.list.date.future';
                }

                // if date is today, timeStart and timeEnd must not be in the future
                if (userDate && userDate >= now) {
                    if (values.timeStart && parse(values.timeStart, 'HH:mm', new Date()) > new Date()) {
                        errors['timeStart'] = 'errors.list.timeStart.future';
                    }

                    if (values.timeEnd && parse(values.timeEnd, 'HH:mm', new Date()) > new Date()) {
                        errors['timeEnd'] = 'errors.list.timeEnd.future';
                    }
                }

                if (values.timeStart && values.timeEnd) {
                    const start = parse(values.timeStart, 'HH:mm', new Date());
                    const end = parse(values.timeEnd, 'HH:mm', new Date());

                    if (isAfter(start, end)) {
                        errors['timeStart'] = errors['timeEnd'] =
                            'Čas začátku vycházky musí být před časem konce vycházky.';
                    }
                }

                if (ListObservationMode.point === values.observationMode && !values.coordinates)
                    errors['coordinates'] = 'errors.list.coordinates.required';

                if (ListObservationMode.line === values.observationMode && (!values.track || values.track.length < 2))
                    errors['track'] = 'errors.list.track.required';

                if (values.coordinates && (!values.coordinates.latitude || !values.coordinates.longitude))
                    errors['coordinates'] = 'errors.list.coordinates.oneEmpty';

                if (
                    values._Municipality &&
                    values._Municipality.latitude &&
                    values._Municipality.longitude &&
                    values.coordinates &&
                    values.coordinates.latitude &&
                    values.coordinates.longitude
                ) {
                    if (
                        distanceTwoPoints(
                            [values._Municipality.latitude, values._Municipality.longitude],
                            [values.coordinates.latitude, values.coordinates.longitude],
                        ) > (values._Municipality.maxDistance ?? 5000)
                    ) {
                        errors['coordinates'] = `errors.list.coordinates.tooFar.value=${
                            values._Municipality?.maxDistance ? Math.round(values._Municipality.maxDistance / 1000) : 5
                        }`;
                    }
                }

                if (values.date && values.secretUntil) {
                    if (values.date > values.secretUntil) errors['secretUntil'] = 'errors.list.secretUntil.tooSmall';
                }

                if (values.secretUntil && !values.secrecyLevel)
                    errors['secrecyLevel'] = 'errors.list.secrecyLevel.empty';

                if (!values.secretUntil && values.secrecyLevel) errors['secretUntil'] = 'errors.list.secretUntil.empty';

                if (values.type === ListTypes.complete) {
                    if (!values.timeStart) errors['timeStart'] = 'errors.list.timeStart.required';
                    if (!values.timeEnd) errors['timeEnd'] = 'errors.list.timeEnd.required';
                } else {
                    if (values.timeEnd && !values.timeStart) {
                        errors['timeStart'] = 'errors.list.timeStart.requiredWithEnd';
                    }
                }

                return errors;
            }}
        >
            {(formikProps) => {
                const {
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    handleBlur,
                    handleChange,
                    submitForm,
                    setValues,
                } = formikProps;

                const setDateValue = (field: string, value: Date | null) => {
                    if (!value) return setFieldValue(field, null);

                    const localDate = format(value, 'yyyy-MM-dd');
                    const utcDate = new Date(localDate + 'T00:00:00Z');

                    setFieldValue(field, utcDate);
                };

                const handleNextClick = async (listType?: EListTypes) => {
                    scrollToFirstError();
                    listType && (await setFieldValue('type', listType));
                    submitForm();
                };

                const canChangeListType = props.new || props.initialValues?.rights?.includes(ListRights.changeListType);

                return (
                    <Form>
                        <CreateListTour />
                        {props.onChange && <FormikObserver onChange={props.onChange} values={values} />}
                        <Paper sx={{ my: 1.75 }}>
                            <Grid container rowSpacing={1.75} columnSpacing={3.5}>
                                <Grid item xs={12}>
                                    <Grid item sx={{ px: 1.75, pb: 1.75 }}>
                                        <Grid container spacing={1.75} sx={{ pb: 1.75 }} className="tour-createlist-3">
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                sx={{ display: 'flex', alignItems: 'flex-start', gap: '1em' }}
                                            >
                                                <DatePicker<Date>
                                                    label="Datum"
                                                    value={values.date || null}
                                                    disableFuture
                                                    format="dd.MM.yyyy"
                                                    onChange={(value) => {
                                                        setDateValue('date', value);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'date',
                                                            name: 'date',
                                                            sx: {
                                                                flexGrow: 1,
                                                            },
                                                            required: true,
                                                            helperText:
                                                                !!errors.date &&
                                                                !!touched.date &&
                                                                translateErrorMessage(errors.date as string),
                                                            error: !!errors.date && !!touched.date,
                                                            onBlur: handleBlur,
                                                        },
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        setDateValue('date', new Date());
                                                    }}
                                                    sx={{ mt: 1.25 }}
                                                    tabIndex={-1}
                                                >
                                                    Dnes
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        const yesterday = new Date();
                                                        yesterday.setDate(yesterday.getDate() - 1);
                                                        setDateValue('date', yesterday);
                                                    }}
                                                    sx={{ mt: 1.25 }}
                                                    tabIndex={-1}
                                                >
                                                    Včera
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TimePicker<Date>
                                                    label="Začátek vycházky"
                                                    value={
                                                        values.timeStart
                                                            ? parse(values.timeStart, 'HH:mm', new Date())
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    onChange={async (value) => {
                                                        await setFieldValue(
                                                            'timeStart',
                                                            isValid(value)
                                                                ? format(value as unknown as Date, 'HH:mm')
                                                                : null,
                                                        );
                                                        setFieldTouched('timeStart', true);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'timeStart',
                                                            name: 'timeStart',
                                                            fullWidth: true,
                                                            helperText:
                                                                !!errors.timeStart &&
                                                                !!touched.timeStart &&
                                                                translateErrorMessage(errors.timeStart),
                                                            error: !!errors.timeStart && !!touched.timeStart,
                                                            required: values.type === ListTypes.complete,
                                                            onBlur: handleBlur,
                                                        },
                                                    }}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <TimePicker
                                                    label="Konec vycházky"
                                                    value={
                                                        values.timeEnd
                                                            ? parse(values.timeEnd, 'HH:mm', new Date())
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    onChange={async (value) => {
                                                        await setFieldValue(
                                                            'timeEnd',
                                                            isValid(value)
                                                                ? format(value as unknown as Date, 'HH:mm')
                                                                : null,
                                                        );
                                                        setFieldTouched('timeEnd', true);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'timeEnd',
                                                            name: 'timeEnd',
                                                            fullWidth: true,
                                                            helperText:
                                                                !!errors.timeEnd &&
                                                                !!touched.timeEnd &&
                                                                translateErrorMessage(errors.timeEnd),
                                                            error: !!errors.timeEnd && !!touched.timeEnd,
                                                            required: values.type === ListTypes.complete,
                                                            onBlur: handleBlur,
                                                        },
                                                    }}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ px: 1.75, pb: 1.75, bgcolor: 'primary.300' }}
                                        className="tour-createlist-4 tour-createlist-5 tour-createlist-6"
                                    >
                                        <ObsListLocationForm formikProps={formikProps} />
                                    </Grid>
                                    <Grid item container spacing={1.75} sx={{ p: 1.75 }}>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={6}
                                            rowSpacing={2.2}
                                            columnSpacing={1.75}
                                            className="tour-createlist-7"
                                        >
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="observersText"
                                                    name="observersText"
                                                    label={
                                                        <>
                                                            <PersonRounded sx={{ color: 'action.active' }} />
                                                            Pozorovatelé
                                                        </>
                                                    }
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.observersText || ''}
                                                    error={!!errors.observersText && !!touched.observersText}
                                                    helperText={
                                                        !!errors.observersText &&
                                                        !!touched.observersText &&
                                                        translateErrorMessage(errors.observersText)
                                                    }
                                                    InputProps={{
                                                        startAdornment: identity?.name ? (
                                                            <InputAdornment position="start" sx={{ pr: 1 }}>
                                                                <Chip label={identity.name} size="small" />
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            gap: '0.5rem',
                                                            '&:not(.MuiInputLabel-shrink)': {
                                                                top: '-0.2rem',
                                                            },
                                                            '&.MuiInputLabel-shrink .MuiSvgIcon-root': {
                                                                color: 'secondary.main',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <NumberField
                                                    id="observersNumber"
                                                    name="observersNumber"
                                                    label="Celk. počet pozorovatelů"
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setFieldValue('observersNumber', e.target.value);
                                                    }}
                                                    value={values.observersNumber || ''}
                                                    error={!!errors.observersNumber && !!touched.observersNumber}
                                                    helperText={
                                                        !!errors.observersNumber &&
                                                        !!touched.observersNumber &&
                                                        translateErrorMessage(errors.observersNumber)
                                                    }
                                                    min={0}
                                                    step={1}
                                                    max={100}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={7} display="flex" alignItems="center">
                                                <FormControl fullWidth size="small" sx={{ pl: [0, 1.75] }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="hideObservers"
                                                                name="hideObservers"
                                                                checked={values.hideObservers ?? false}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                        label="Utajit jména všech pozorovatelů"
                                                    />
                                                    {!!errors.hideObservers && !!touched.hideObservers && (
                                                        <FormHelperText error>
                                                            {translateErrorMessage(errors.hideObservers)}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={6}
                                            spacing={1.75}
                                            className="tour-createlist-8"
                                        >
                                            <Grid item xs={12} md={2.5}>
                                                <FormControl fullWidth size="small">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="_Secret"
                                                                name="_Secret"
                                                                checked={values._Secret}
                                                                onBlur={handleBlur}
                                                                onChange={() => {
                                                                    setValues({
                                                                        ...values,
                                                                        _Secret: !values._Secret,
                                                                        secretUntil:
                                                                            !values._Secret && values.date
                                                                                ? addYears(values.date, 1)
                                                                                : null,
                                                                        secrecyLevel: null,
                                                                    });
                                                                }}
                                                                color="secondary"
                                                            />
                                                        }
                                                        label="Utajit"
                                                    />
                                                    {!!errors._Secret && !!touched._Secret && (
                                                        <FormHelperText error>
                                                            {translateErrorMessage(errors._Secret)}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {values._Secret && (
                                                <>
                                                    <Grid item xs={12} md={5.5}>
                                                        <SecrecySelect
                                                            id="secrecyLevel"
                                                            name="secrecyLevel"
                                                            label="Úroveň utajení"
                                                            size="small"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.secrecyLevel || ''}
                                                            error={!!errors.secrecyLevel && !!touched.secrecyLevel}
                                                            helperText={
                                                                !!errors.secrecyLevel &&
                                                                !!touched.secrecyLevel &&
                                                                translateErrorMessage(errors.secrecyLevel)
                                                            }
                                                            filterOptions={(options) =>
                                                                options.filter((option) =>
                                                                    option.category.includes(
                                                                        SecrecyCategory.listInsert,
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <DatePicker
                                                            label="Utajit do"
                                                            value={values.secretUntil || null}
                                                            onChange={(value) => setDateValue('secretUntil', value)}
                                                            minDate={values.date || undefined}
                                                            slotProps={{
                                                                textField: {
                                                                    id: 'secretUntil',
                                                                    name: 'secretUntil',
                                                                    onBlur: handleBlur,
                                                                    size: 'small',
                                                                    fullWidth: true,
                                                                    error:
                                                                        !!errors.secretUntil && !!touched.secretUntil,
                                                                    helperText:
                                                                        !!errors.secretUntil &&
                                                                        !!touched.secretUntil &&
                                                                        translateErrorMessage(errors.secretUntil),
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="note"
                                                    name="note"
                                                    label={
                                                        <>
                                                            <EditRounded sx={{ color: 'action.active' }} />
                                                            Poznámka k&nbsp;vycházce
                                                        </>
                                                    }
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.note || ''}
                                                    error={!!errors.note && !!touched.note}
                                                    helperText={
                                                        !!errors.note &&
                                                        !!touched.note &&
                                                        translateErrorMessage(errors.note)
                                                    }
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            gap: '0.5rem',
                                                            '&:not(.MuiInputLabel-shrink)': {
                                                                top: '-0.2rem',
                                                            },
                                                            '&.MuiInputLabel-shrink .MuiSvgIcon-root': {
                                                                color: 'secondary.main',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <code>{JSON.stringify(errors)}</code>
                                    <br />
                                    <code>{JSON.stringify(values)}</code>
                                    <br />
                                    <code>{JSON.stringify(touched)}</code>
                                    <br />
                                </Grid> */}
                            </Grid>
                        </Paper>
                        <Box
                            sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: '1rem' }}
                            className="tour-createlist-9"
                        >
                            {canChangeListType && (
                                <>
                                    <Tooltip
                                        title="Zvolte, pokud zadáváte pouze vybraná pozorování."
                                        placement="bottom"
                                    >
                                        <Button
                                            onClick={async () => {
                                                await handleNextClick(ListTypes.partial);
                                            }}
                                            variant={
                                                props.new
                                                    ? 'contained'
                                                    : values.type === ListTypes.partial
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            color="secondary"
                                        >
                                            {props.new && <span>Zadat částečný seznam</span>}
                                            {!props.new && (
                                                <span>
                                                    {values.type === ListTypes.partial
                                                        ? `Pokračovat úpravou částečného seznamu`
                                                        : 'Změnit na částečný seznam'}
                                                </span>
                                            )}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title="Zvolte, pokud zadáváte kompletní seznam všech pozorovaných jedinců během vycházky."
                                        placement="bottom"
                                    >
                                        <Button
                                            onClick={async () => {
                                                await handleNextClick(ListTypes.complete);
                                            }}
                                            variant={
                                                props.new
                                                    ? 'contained'
                                                    : values.type === ListTypes.complete
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            color="secondary"
                                        >
                                            {props.new && <span>Zadat kompletní seznam</span>}
                                            {!props.new && (
                                                <span>
                                                    {values.type === ListTypes.complete
                                                        ? `Pokračovat úpravou kompletního seznamu`
                                                        : 'Změnit na kompletní seznam'}
                                                </span>
                                            )}
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                            {!canChangeListType && (
                                <Button
                                    onClick={async () => {
                                        await handleNextClick(undefined);
                                    }}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Pokračovat úpravou seznamu
                                </Button>
                            )}
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ObsListDetailsForm;
