import { Box, Grid, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { IObsListSearchFormValues } from '../ObsListsSearch';
import PlacesAutocomplete from '../../../../components/formControls/PlacesAutocomplete';
import CoordinatesMap from '../../../../components/map/CoordinatesMap';
import { TooltipMobile } from '../../../../components/Tooltip';
import { useEffect } from 'react';
import { useJoyride } from '../../../../services/joyrideContext';
import { MapCategory } from '../../../../components/map/OpenStreetMap';
import ClearableTextField from '../../../../components/formControls/ClearableTextField';
import SquaresAutocomplete from './SquareAutocomplete';

export enum EPlaceMode {
    TeritorialUnit = 'teritorialUnit',
    Coordinates = 'coordinates',
    Squares = 'squares',
}

interface ObsListsSearchPlaceProps {
    formikProps: FormikProps<IObsListSearchFormValues>;
}

const ObsListsSearchPlace: React.FunctionComponent<ObsListsSearchPlaceProps> = (props) => {
    const { values, touched, errors, handleChange, handleBlur, setFieldValue } = props.formikProps;
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (data.step.target === '.tour-advancedsearch-7') handleModeChange(undefined, EPlaceMode.Coordinates);
            if (data.step.target === '.tour-advancedsearch-8') handleModeChange(undefined, EPlaceMode.TeritorialUnit);
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const handleModeChange = (e: React.MouseEvent<HTMLElement> | undefined, value: EPlaceMode) => {
        if (!value) return;

        props.formikProps.setTouched({
            ...props.formikProps.touched,
            coordinatesWithRadius: false,
            territorialUnitId: false,
        });
        props.formikProps.setValues({
            ...props.formikProps.values,
            _placeMode: value,
            coordinatesWithRadius: undefined,
            territorialUnitId: undefined,
        });
    };

    return (
        <Box className="ObsListsSearchPlace tour-advancedsearch-7 tour-advancedsearch-8">
            <Grid container spacing={1.75}>
                <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                        Místo:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                    <ToggleButtonGroup
                        value={values._placeMode}
                        onChange={handleModeChange}
                        id="_placeMode"
                        exclusive
                        aria-label="vyberte jak chcete zadat oblast pozorování"
                        fullWidth
                        size="small"
                    >
                        <TooltipMobile title="Umožňuje vybrat GPS souřadnice a oblast kolem nich">
                            <ToggleButton
                                value={EPlaceMode.Coordinates}
                                selected={values._placeMode == EPlaceMode.Coordinates}
                            >
                                Mapa
                            </ToggleButton>
                        </TooltipMobile>
                        <TooltipMobile title="Umožňuje vybrat jednu či více obcí, okresů nebo krajů">
                            <ToggleButton
                                value={EPlaceMode.TeritorialUnit}
                                selected={values._placeMode == EPlaceMode.TeritorialUnit}
                            >
                                Územní celky
                            </ToggleButton>
                        </TooltipMobile>
                        <TooltipMobile title="Umožňuje vybrat jeden či více čtverců ze systému EEA10KM nebo KMFE">
                            <ToggleButton value={EPlaceMode.Squares} selected={values._placeMode == EPlaceMode.Squares}>
                                Čtverce
                            </ToggleButton>
                        </TooltipMobile>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <ClearableTextField
                        fullWidth
                        id="siteName"
                        name="siteName"
                        value={values.siteName || ''}
                        className={values.siteName ? 'nonEmpty' : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Název lokality"
                        placeholder="Vyhledávejte dle názvu lokality"
                        error={touched.siteName && !!errors.siteName}
                        helperText={touched.siteName && errors.siteName}
                        color="secondary"
                    />
                </Grid>
                {values._placeMode == EPlaceMode.TeritorialUnit && (
                    <Grid item xs={12}>
                        <PlacesAutocomplete
                            id="territorialUnitId"
                            name="territorialUnitId"
                            label="Kraj, okres, obec, část obce"
                            value={values.territorialUnitId || null}
                            onChange={(value) => {
                                props.formikProps.setFieldValue('territorialUnitId', value);
                            }}
                            onBlur={handleBlur}
                            multiple
                            TextFieldProps={{
                                error: touched.territorialUnitId && !!errors.territorialUnitId,
                                helperText: touched.territorialUnitId && errors.territorialUnitId,
                                color: 'secondary',
                            }}
                            AutocompleteProps={{
                                color: 'secondary',
                            }}
                            highlightWhenFilled
                            enableGrouping
                        />
                    </Grid>
                )}
                {values._placeMode == EPlaceMode.Coordinates && (
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        <Typography
                            variant="body2"
                            component="span"
                            fontWeight="bold"
                            sx={{
                                position: 'absolute',
                                top: '0.7em',
                                bgcolor: 'background.paper',
                                zIndex: 450,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                py: 0.25,
                                px: 1,
                                borderRadius: 'var(--mui-shape-borderRadius)',
                            }}
                        >
                            Zadejte oblast kliknutím do&nbsp;mapy
                        </Typography>
                        <CoordinatesMap
                            formikProps={props.formikProps}
                            coordsValues={values.coordinatesWithRadius || {}}
                            center={
                                values.coordinatesWithRadius
                                    ? [values.coordinatesWithRadius.latitude, values.coordinatesWithRadius.longitude]
                                    : undefined
                            }
                            inputNamePrefix="coordinatesWithRadius."
                            defaultRadius={1}
                            height="294px"
                            highlightWhenFilled
                            mapCategory={MapCategory.SEARCH}
                            PasteButtonProps={{
                                sx: {
                                    marginTop: '8px',
                                },
                            }}
                        />
                    </Grid>
                )}
                {values._placeMode == EPlaceMode.Squares && (
                    <Grid item xs={12}>
                        <SquaresAutocomplete />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ObsListsSearchPlace;
